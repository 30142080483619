import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { ModuleEnum } from '../_utils/unums/module.enum';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private firebaseService: FirebaseService,
    private storage: Storage
  ) {
  }

  public async getModuleSettings(module: ModuleEnum | string): Promise<{ key: string; defaultUserGrade: boolean }> {
    const companyDefaultUserGrade = await this.getCompanyDefaultUserGrade(module);
    if (companyDefaultUserGrade) {
      return companyDefaultUserGrade;
    }
    return await this.firebaseService.getDocument(`settings/modules/${module}`);
  }

  public async getMenuSettings(menuSettings: any[], companyId: string): Promise<any> {
    const menu = [...menuSettings];
    if (!companyId) {
      return menu;
    }
    const companyMenu = await this.firebaseService.getDocument(`companies/${companyId}/settings/menu`);
    if (!companyMenu) {
      return menu;
    }
    return menu.map(menuModule => {
      if (!menuModule?.items) {
        return menuModule;
      }
      menuModule.items = menuModule.items.map((item: any) => {
        item.hidden = !!companyMenu[menuModule.module]?.items?.[item?.key]?.hidden;
        return item;
      });
      return menuModule;
    });
  }

  public async getHeaderSettings(): Promise<any> {
    return await this.getCompanySettings(`header`);
  }

  public async getCompanyAssessments(key: string): Promise<any> {
    return await this.getCompanySettings(`assessments/${key}`);
  }

  public async getContentCompany(key: string): Promise<any> {
    return await this.getCompanySettings(`content/${key}`);
  }

  public getCompanyTutors = async (): Promise<string[]> => {
    const user = await this.storage.get('user');
    return await this.firebaseService.getDocument(`companies/${user.companyId}/settings/tutors`);
  };

  public getForumManagers = async (): Promise<string[]> => await this.firebaseService.getDocument(`settings/forumManagers`);

  public getDefaultRedirectPath = async (module: string): Promise<string> =>{
    const path = await this.firebaseService.getDocument(`settings/modules/${module}/defaultRedirectPath`);
    return path || 'intro';
  };

  private async getCompanyDefaultUserGrade(module: ModuleEnum | string): Promise<{
    key: string;
    defaultUserGrade: boolean;
  }> {
    return await this.getCompanySettings(`modules/${module}`);
  }

  private async getCompanySettings(path: string): Promise<any> {
    const user = await this.storage.get('user');
    return await this.firebaseService.getDocument(`companies/${user.companyId}/settings/${path}`);
  }

}
