import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AssessmentService } from '../_services/assessment.service';
import { UserJourneyService } from '../_services/user-journey.service';
import { TutorialService } from '../_services/tutorial.service';
import { SettingsService } from '../_services/settings.service';
import { ModuleService } from '../_services/module.service';
import { ModuleEnum } from '../_utils/unums/module.enum';

@Injectable({
  providedIn: 'root'
})
export class PreTrainingGuard implements CanActivate {

  constructor(
    private assessmentService: AssessmentService,
    private router: Router,
    private userJourneyService: UserJourneyService,
    private tutorialService: TutorialService,
    private settingsService: SettingsService,
    private moduleService: ModuleService
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const module = route.params.module;
    const hasModulePreTraining = await this.moduleService.getStatusModuleSection(
      ModuleEnum[module],
      'defaultUserGrade'
    );

    const isVGFlowOrDemo = await this.tutorialService.isVGFlow() || await this.userJourneyService.isDemo();

    if (isVGFlowOrDemo) {
      return true;
    }
    const moduleSettings = await this.settingsService.getModuleSettings(module);

    if (moduleSettings?.defaultUserGrade) {
      return true;
    }
    if (hasModulePreTraining) {
      this.router.navigate([route.params.module, 'courses']).then();
      return false;
    }
    const hasPretraining = await this.assessmentService.hasPreTraining(route.params.module);
    if (!hasPretraining) {
      this.router.navigate([route.params.module, 'courses']).then();
      return false;
    }
    return true;
  }

}
