import { Injectable, NgZone } from '@angular/core';
import { GoogleAuthProvider } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Storage } from '@ionic/storage-angular';
import { FirebaseService } from './firebase.service';
import moment from 'moment';
import { UserJourneyService } from './user-journey.service';
import { EventsService } from './events.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: any;
  userStatus: boolean = false;
  public userRole: string;
  private timeout: any;
  private idelTime: number = 5 * 60 * 1000;    // 5 minutes

  constructor(
    private storage: Storage,
    private firebaseService: FirebaseService,
    private userJourneyService: UserJourneyService,
    public afAuth: AngularFireAuth,
    private ngZone: NgZone,
    private userService: UserService
  ) {
    this.storage.create();

    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(async (user) => {
      if (user && user?.emailVerified && await this.isApprovedUser(user.uid)) {
        this.userJourneyService.activeDefaultModule(user.uid).then();
        this.userJourneyService.listenAndUpdateUserJourney(user.uid).then();
        this.userJourneyService.setSessionLastLogin(user.uid).then();
        this.setUserRole(user.uid).then();
        if (!this.user) {
          this.updateUserFromDB(user);
        }

        this.setUserOnlineStatus(true);
      }
      else {
        this.userRole = '';
        this.setUserOnlineStatus(false);
        this.userJourneyService.closeUserJourneySubscription();
      }

      window.addEventListener('beforeunload', () => {
        this.setUserOnlineStatus(false);
      });

      this.startInactivityCheck();
    });
  }

  private setUserOnlineStatus(status:boolean = true) {
    if (this.user && this.userStatus !== status) {
      this.userStatus = status;
      this.firebaseService.updateDocument('/users', this.user.uid, { online: status });
    }
  }

  private startInactivityCheck() {
    document.addEventListener('mousemove', () => this.resetTimer(), true);
    document.addEventListener('keypress', () => this.resetTimer(), true);

    this.resetTimer();
  }

  private resetTimer() {
    clearTimeout(this.timeout);
    this.setUserOnlineStatus(true);

    this.ngZone.runOutsideAngular(() => {
      this.timeout = setTimeout(() => {
        this.ngZone.run(() => {
          this.setUserOnlineStatus(false);
        });
      }, this.idelTime);
    });
  }

  // Get user from DB and save data to storage
  updateUserFromDB(user) {
    // Get user from DB to store locally
    this.firebaseService.getDocument(`/users/${user.uid}`).then(async (u: any) => {

      if (!u) return;   // first time login, the user does not exist in the DB
      this.firebaseService.updateDocument('/users', user.uid, {last_login: new Date().getTime()}).then(async _ => {

        u.uid = user.uid;
        this.user = u;
        await this.storage.set('user', this.user);
      })
    })
  }

  // Sign in email and password
  EmailAuth(data) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(data.email, data.password).then(async res => {
          let user;
          let approvedUser = await this.isUserAuthorized(res.user);
          if (approvedUser) {
            user = {
              ...res.user.providerData[0],
              uid: res.user.uid,
              approved: approvedUser.approved,
              emailVerified: res.user.emailVerified || approvedUser.noEmailVerificationNeeded,
            }
          } else {
            user = {
              ...res.user.providerData[0],
              uid: res.user.uid,
              approved: false,
              emailVerified: res.user.emailVerified
            }
          }

          resolve(user);

        },
        err => reject(err))
    })
  }

  // Sign in with Google
  GoogleAuth() {
    return this.afAuth.signInWithPopup(new GoogleAuthProvider()).then(async res => {
      let user;
      let approvedUser = await this.isUserAuthorized(res.user);
      if (approvedUser) {
        user = {
          ...res.user.providerData[0],
          uid: res.user.uid,
          approved: approvedUser.approved,
        }
      }
      // No value in 'authorized_users' = the user logs-in with goole for the first time
      else {
        // Autorize the user
        this.firebaseService.updateDocument('/authorized_users', res.user.uid, true);
        user = {
          ...res.user.providerData[0],
          uid: res.user.uid,
          approved: true,
        }
      }

      return user;

    })
      .catch((error) => {
        console.error(error);
      });
  }

  Register(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(value.email, value.password).then(res => resolve(res), err => reject(err));
    })
  }

  async SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
      });
  }

  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail).then(() => {
      return {success: true};
    })
      .catch((error) => {
        let message;
        if (error.code == "auth/user-not-found") message = "The email address was not found.";
        if (error.code == "auth/invalid-email") message = "Invalid email";
        return {success: false, message};
      });
  }

  isUserAuthorized(user) {
    return new Promise<any>((resolve, reject) => {
      this.firebaseService.getDocument('/authorized_users/' + user.uid).then(res => {
          resolve(res)
        },
        err => {
          reject(err)
        });
    });
  }

  async logout(callback?: () => Promise<any>, reload = true) {
    await this.storage.clear();
    await this.afAuth.signOut();
    if (callback) {
      await callback();
    }
    if (reload) {
      location.reload();
    }
  }

  async getUserJourney(company, uid) {
    return this.firebaseService.getDocument(`user_journey/${company}/${uid}`).then((journey: any) => {
      if (journey) {
        if (journey.demo && (moment(journey.demo) < moment())) {
          return false;
        } else {
          this.userJourneyService.listenAndUpdateUserJourney(uid);
          return true;
        }
      } else return true
    })
  }

  async getUser() {
    return this.storage.get('user').then((user) => {
      if (!user) return null;
      return user;
    });
  }

  async isConnected() {
    const user = await this.storage.get('user');
    return !!user;
  }

  private async isApprovedUser(uid: string): Promise<boolean> {
    return !!(await this.firebaseService.getDocument(`authorized_users/${uid}/approved`));
  }

  private  setUserRole = async (uid: string): Promise<void >  => {
    this.userRole= await this.userService.getUserProperty(uid, 'role');
  };
}
