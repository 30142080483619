import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TutorialService } from '../_services/tutorial.service';
import { UserJourneyService } from '../_services/user-journey.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentModuleGuard implements CanActivate {
  constructor(
    private tutorialService: TutorialService,
    private userJourneyService: UserJourneyService,
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (this.tutorialService.isGuidedVisit) {
      return true;
    }
    await this.userJourneyService.updateJourneyCurrentModule(route.params.module);
    return true;
  }

}
