import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private firebaseService: FirebaseService,
  ) {
  }

  public getUser = async (uid: string): Promise<any> => await this.firebaseService.getDocument(`users/${uid}`);
  public getUserProperty = async (uid: string, path: string): Promise<any> => await this.firebaseService.getDocument(`users/${uid}/${path}`);

  public async updateUserAvailability(companyLowercase: string, uid: string, availability: any): Promise<any> {
    const path = `user_journey/${companyLowercase}`;
    return this.firebaseService.updateDocument(path, uid, { availability });
  }

  public async getPeerName(peerId: string): Promise<string> {
    return this.firebaseService.getDocument(`users/${peerId}/displayName`);
  }
}
