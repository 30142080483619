import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { UserRedirectGuard } from './_guards/user-redirect.guard';
import { CompleteModuleGuard } from './_guards/complete-module.guard';
import { PreTrainingGuard } from './_guards/pre-training.guard';
import { UserDemoGuard } from './_guards/user-demo.guard';
import { GuidedVisitGuard } from './_guards/guided-visit.guard';
import { ModuleGuard } from './_guards/module.guard';
import { CurrentModuleGuard } from './_guards/current-module.guard';
import { DescriptionSectionModule } from './description_module/description-module.module';

const routes: Routes = [
  {
    path: 'video',
    loadChildren: () => import('./video/video.module').then(m => m.VideoPageModule),
  },
  {
    path: '',
    canActivateChild: [UserRedirectGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenancePageModule)
      },
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
      },
      {
        path: 'folder/:id',
        loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule)
      },
      {
        path: ':module/courses', // -> :module/home
        loadChildren: () => import('./app-modules/feedback/feedback-home/feedback-home.module').then(m => m.FeedbackHomePageModule),
        canActivate: [AuthGuard]
      },
      {
        path: ':module/intro',
        loadChildren: () => import('./app-modules/feedback/feedback-intro/feedback-intro.module').then(m => m.FeedbackIntroPageModule),
        canActivate: [AuthGuard, GuidedVisitGuard, CurrentModuleGuard]
      },
      {
        path: 'intro',
        loadChildren: () => import('./intro/intro.module').then(m => m.IntroPageModule), canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule),
        canActivate: [AuthGuard, GuidedVisitGuard]
      },
      {
        path: 'dashboard/:step',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule),
        canActivate: [AuthGuard, UserDemoGuard]
      },
      {
        path: ':module/learning',
        loadChildren: () => import('./learning/learning.module').then(m => m.LearningPageModule),
        canActivate: [AuthGuard, GuidedVisitGuard, CurrentModuleGuard]
      },
      {
        path: ':module/simulation-peer/:id',
        loadChildren: () => import('./roleplay/roleplay.module').then(m => m.RoleplayPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'peers',
        loadChildren: () => import('./peers/peers.module').then(m => m.PeersPageModule), canActivate: [AuthGuard]
      },
      {
        path: ':module/simulations',
        loadChildren: () => import('./simulations/simulations.module').then(m => m.SimulationsPageModule),
        canActivate: [AuthGuard, PreTrainingGuard, GuidedVisitGuard, CurrentModuleGuard]
      },
      {
        path: 'roleplays',
        loadChildren: () => import('./roleplays/roleplays.module').then(m => m.RoleplaysPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'roleplay-sales',
        loadChildren: () => import('./roleplay-sales/roleplay-sales.module').then(m => m.RoleplaySalesPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'playground',
        loadChildren: () => import('./playground/playground.module').then(m => m.PlaygroundPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: ':module/assessments',
        loadChildren: () => import('./assessments/assessments.module').then(m => m.AssessmentsPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule), canActivate: [AuthGuard]
      },
      {
        path: ':module/simulations-peers',
        loadChildren: () => import('./simulations-peers/simulations-peers.module').then(m => m.SimulationsPeersPageModule),
        canActivate: [AuthGuard, CompleteModuleGuard, PreTrainingGuard, GuidedVisitGuard, CurrentModuleGuard]
      },
      {
        path: ':module/simulation-context',
        loadChildren: () => import('./simulation-context/simulation-context.module').then(m => m.SimulationContextPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'simulation-peers-add',
        loadChildren: () => import('./simulation-peers-add/simulation-peers-add.module').then(m => m.PeerSimulationAddPageModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
      },
      {
        path: 'set-journey',
        loadChildren: () => import('./__test/set-journey/set-journey.module').then(m => m.SetJourneyPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'simulation-peers-reschedule',
        loadChildren: () => import('./simulation-peers-reschedule/simulation-peers-reschedule.module').then(m => m.SimulationPeersReschedulePageModule),
        canActivate: [AuthGuard]
      },
      {
        path: ':module/simulation-avatar/:id',
        data: {reuse: false},
        loadChildren: () => import('./simulation-avatar/simulation-avatar.module').then(m => m.SimulationAvatarPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: ':module/simulations-list',
        loadChildren: () => import('./simulations-list/simulations-list.module').then(m => m.SimulationsListPageModule),
        canActivate: [AuthGuard, CompleteModuleGuard, PreTrainingGuard, GuidedVisitGuard, ModuleGuard, CurrentModuleGuard]
      },
      {
        path: ':module/user-grade',
        loadChildren: () => import('./user-grade/user-grade.module').then(m => m.UserGradePageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'not-found',
        loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: ':module/simulations-peers-list',
        loadChildren: () => import('./simulations-list-p2p/simulations-list-p2p.module').then(m => m.SimulationsListP2pPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: ':module/simulations-peers-msg',
        loadChildren: () => import('./simulations-peers-msg/simulations-peers-msg.module').then(m => m.SimulationsPeersMsgPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: ':module/simulation-avatar-evaluation',
        loadChildren: () => import('./simulation-avatar-evaluation/simulation-avatar-evaluation.module').then(m => m.SimulationAvatarEvaluationPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: ':module/greeting',
        loadChildren: () => import('./greeting/greeting.module').then(m => m.GreetingPageModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'forum',
    loadChildren: () => import('./forum/forum.module').then(m => m.ForumPageModule)
  },
  {
    path: 'forum-post',
    loadChildren: () => import('./forum-post/forum-post.module').then(m => m.ForumPostPageModule)
  },
  {
    path: 'transcription',
    loadChildren: () => import('./__test/transcription/transcription.module').then(m => m.TranscriptionPageModule)
  },
  {
    path: 'all-modules',
    loadChildren: () => import('./all-modules/all-modules.module').then(m => m.AllModulesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'all-modules/description/:moduleId',
    loadChildren: () => import('./description_module/description-module.module').then(m => m.DescriptionSectionModule),
  },
  {path: '**', redirectTo: '/not-found'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
