import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(
    private firebaseService: FirebaseService,
    private storage: Storage
  ) {
  }

  public getCompanyModules = async (): Promise<any[]> => {
    const user = await this.storage.get('user');
    const modules: string[] = await this.firebaseService.getDocument(`companies/${user.companyId}/settings/availableModules`);
    if (modules.length > 0) {
      const modulesReq =  [];
      for (const module of modules) {
        modulesReq.push(this.getCompanyModule(module));
      }
      return await Promise.all(modulesReq);
    }
    return await this.firebaseService.getDocumentsListOnce(`settings/modules`);
  };

  public getCompanyModule = async (key: string): Promise<any> => {
    const user = await this.storage.get('user');
    const [userModule, module] = await Promise.all([
      this.firebaseService.getDocument(`companies/${user.companyId}/settings/modules/${key}`),
      this.firebaseService.getDocument(`settings/modules/${key}`)
    ]);
    const result = {
      ...(module || {}),
      ...userModule
    };
    return result;
  };

  public geModulePercentages = async (key: string): Promise<any> => {
    const user = await this.storage.get('user');
    const [userModule, module] = await Promise.all([
      this.firebaseService.getDocument(`companies/${user.companyId}/settings/modules/${key}/percentages`),
      this.firebaseService.getDocument(`settings/modules/${key}/percentages`)
    ]);
    return {
      ...(module || {}),
      ...userModule
    };
  };

  public getUserModules = async (): Promise<any[]> => {
    const user = await this.storage.get('user');
    return await this.firebaseService.getDocumentsListOnce(`user_journey/${user.companyLowercase}/${user.uid}/modules`);
  };

  public getUserModule = async (moduleKey: string): Promise<any> => {
    const user = await this.storage.get('user');
    return await this.firebaseService.getDocumentsListOnce(`user_journey/${user.companyLowercase}/modules/${moduleKey}`);
  };

  public getStatusModuleSections = async (module: string): Promise<any> => {
    const [avatar, bestPractices, introduction, peer] = await Promise.all([
      this.getStatusModuleSection(module, 'avatar'),
      this.getStatusModuleSection(module, 'bestPractices'),
      this.getStatusModuleSection(module, 'introduction'),
      this.getStatusModuleSection(module, 'peer'),
    ]);
    return {
      avatar,
      bestPractices,
      introduction,
      peer
    };
  };

  public getStatusModuleSection = async (module: string, section: string): Promise<boolean> => {
    const user = await this.storage.get('user');
    let result: boolean = await this.firebaseService.getDocument(`companies/${user.companyId}/settings/modules/${module}/${section}`);
    if (result === null) {
      result = await this.firebaseService.getDocument(`settings/modules/${module}/${section}`);
    }
    return result || false;
  };
}
