export const environment = {
  name: "development",
  version: "2.0.0807",
  features: null,
  production: false,
  //server: 'http://localhost:3001',
  server: 'https://whale-app-z27jt.ondigitalocean.app',
  dbUrl: '', // for development use
  //dbUrl: '/_dev', // for development use
  wherebyRoom: 'https://simskills.whereby.com/test37dd3adc-cefb-4d49-a163-5361f845ee8a',
  firebaseFunctionUrl: 'https://us-central1-simskills-io-dev.cloudfunctions.net',
  fireabseStorageUrl: 'https://firebasestorage.googleapis.com/v0/b/simskills-io.appspot.com/o',
  firebase: {
    apiKey: "AIzaSyAXHf5Db-f5864U_3yFeH0Ozt831d_Hx5o",
    authDomain: "simskills-io-dev.firebaseapp.com",
    databaseURL: "https://simskills-io-dev-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "simskills-io-dev",
    storageBucket: "simskills-io-dev.appspot.com",
    messagingSenderId: "348758560763",
    appId: "1:348758560763:web:67c3cc4d42fbb01787855e"
  },
  aws: {
    lambdaGetSignedUrl: "https://noaiqizp6ec663r2vvoyk6jkza0qqwbi.lambda-url.us-east-1.on.aws",
  },
  dashboardUrl: 'https://dev.dashboard.simskills.io'
 };
