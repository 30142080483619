export enum ModuleEnum {
  allModules = 'allModules',
  feedback = 'feedback',
  conversations = 'conversations',
  motivation = 'motivation',
  interviews = 'interviews',
  salesAndServices = 'sales',
  health_dialogues = 'dialogues',
  dialogues = 'dialogues',
  demo = 'demo',
  telefonica = 'telefonica',
}
