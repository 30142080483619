import { Pipe, PipeTransform } from '@angular/core';
import { ModuleService } from '../_services/module.service';
import { ModuleEnum } from '../_utils/unums/module.enum';

@Pipe({
  name: 'enabledModuleSection'
})
export class EnabledModuleSectionPipe implements PipeTransform {
  constructor(private readonly moduleService: ModuleService) {}

  transform(module: ModuleEnum | string, section: string): Promise<boolean> {
    return this.moduleService.getStatusModuleSection(module, section);
  }

}
