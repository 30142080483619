import { Component, Inject, ViewChild } from '@angular/core';
import { IonMenu, ModalController, Platform, ToastController } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { Event, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { register } from 'swiper/element/bundle';
import { AuthService } from './_services/auth.service';
import { EventsService } from './_services/events.service';
import { environment } from '../environments/environment';
import { GuidedVisitModalComponent } from './_components/guided-visit-modal/guided-visit-modal.component';
import { TutorialService } from './_services/tutorial.service';
import { ComponentBase } from './_utils/component.base';
import { ModuleEnum } from './_utils/unums/module.enum';
import { SettingsService } from './_services/settings.service';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { UserScheduledSimulationsService } from './_services/user-scheduled-simulations.service';
import { UserService } from './_services/user.service';
import { CompanyService } from './_services/company.service';
import { ModuleService } from './_services/module.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent extends ComponentBase {
  @ViewChild('mainMenu', {static: true})
  protected mainMenu: IonMenu;
  url;
  user;
  showMenu = false;

  features;
  version = environment.version;

  routerSubs;

  msg1 = 'is now listening to the context of the simulation and will soon be joining the simulation.';
  msg2 = 'your peer to the practice, has just logged in.';
  msg3 = 'is waiting for you to start the simulation.';

  public testPages = [];
  public appPages: any[];

  guideVisit;
  returnUrl;
  protected userJourney: any;
  protected role: string;
  protected rolesArray:  string[];
  protected isManagerOrAdminRole = false;
  private availableModules: ModuleEnum[];
  private p2pScheduleSubscription: Subscription;
  private userJourneySubscription: Subscription;
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private platform: Platform,
    private toastController: ToastController,
    public authService: AuthService,
    public translate: TranslateService,
    private events: EventsService,
    private modalCtrl: ModalController,
    private tutorialService: TutorialService,
    private settingsService: SettingsService,
    private userScheduledSimulationsService: UserScheduledSimulationsService,
    private userService: UserService,
    private companyService: CompanyService,
    private moduleService: ModuleService,
  ) {

    super();
    this.initVariables();
    this.listenAuthState();
    this.platform.ready().then(async () => {
      this.setFavicon();
      this.initApp();
    });

    this.router.routeReuseStrategy.shouldReuseRoute = () => false; // So the simulation pages code (like roleplay.ts) will reload and not save last state

    this.routerSubs = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.url = event.url;
        if (['/', '/login', '/not-found'].includes(event.url))
          this.showMenu = false; // Show/Hide left-side menu
        else this.showMenu = true;
      }
    });

    this.tutorialService.initGuideVisitHandler()

    // Update user data after login
    // Open menu sub items according the current page the user is in
    this.events.getObservable().subscribe((data) => {
      if (data.user) {
        // After login
        this.user = data.user;
        this.setLanguage();
        this.validateJourneyDemo();
        this.getP2PSchedule();
      }
      else if (data.menu) {
        const page = this.appPages.find((p: any) => p.module === this.getSelectedModule());
        if (page) page.showItems = true;
        if (data.submenu && page.items) {
          let subpage = page.items.find((p) => p.name == data.submenu);
          if (subpage) subpage.available = true;
        }
      }
      else if (data.refreshsimulations) {
        // when clicking the refresh button in /simulations-peers
        this.getP2PSchedule();
      }
    });
  }

  private initVariables(): void {
    this.availableModules = [
      ModuleEnum.feedback,
      ModuleEnum.conversations,
      ModuleEnum.motivation,
      ModuleEnum.interviews,
      ModuleEnum.salesAndServices,
      ModuleEnum.health_dialogues,
      ModuleEnum.demo,
      ModuleEnum.telefonica
    ];

    this.appPages = [];
    this.initMenuListener();
    this.initActiveMenuListener();

    // Show new features to test in dev
    this.features = environment.features ? `<ul>${environment.features.map(item => `<li> - ${item}</li>`).join('')}</ul>` : null;
    this.rolesArray = ['manager', 'admin'];
  }

  private setFavicon() {
    if (environment.name != "production") {
      let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'icon');
      link.setAttribute('href', `assets/icon/favicon${environment.name == "development" ? '_dev' : environment.name == "staging" ? '_staging' : ''}.png`);
      this.doc.head.appendChild(link);
    }
  }

  async initApp() {
    let self = this;
    this.user = await this.authService.getUser();

    this.guideVisit = await this.storage.get('guided_visit');

    if (this.url.split('?')[0] === '/video') {
      return this.router.navigateByUrl(this.url);
    }

    // User is logged in
    if (this.user) {
      this.role = this.user.role;
      if (!this.user.firstName || !this.user.lastName || !this.user.gender) {
        this.router.navigateByUrl('/login');
        return;
      }
      // Check for coming P2P simulations and subscribe to peers actions
      this.getP2PSchedule();
      this.validateJourneyDemo();
    }
    // User in NOT logged in
    else {
      this.returnUrl = true;
      if (self.url !== '/' && !self.url.includes('/login')) {
        this.router.navigate(['/login'], {queryParamsHandling: 'merge', queryParams: {returnUrl: self.url}});
      }
    }
    this.setLanguage();

  }

  ionViewWillLeave() {
    if (this.p2pScheduleSubscription) {
      this.p2pScheduleSubscription.unsubscribe();
    }
    if (this.userJourneySubscription) {
      this.userJourneySubscription.unsubscribe();
    }
    if (this.routerSubs) {
      this.routerSubs.unsubscribe();
    }
  }

  async setLanguage() {
    // Get the user's language: If it's the login page -> Get from query param. If not login page -> Get from localStorage
    // If no language found, get from saved value OR navigator's deafault language.
    let langParam = this.url.includes('/login') ? (new URL(window.location.href)).searchParams.get("lng") : await this.storage.get('lang');
    let lang = this.user && this.user.language ? this.user.language : langParam || navigator.language || 'es-ES';
    if (lang == 'es') lang = "es-ES";
    if (lang == 'en') lang = "en-US";

    let shortLang = lang.split('-')[0];
    moment.locale(shortLang);
    this.translate.use(shortLang);
    this.storage.set('lang', lang);
  }

  getP2PSchedule() {
    this.p2pScheduleSubscription = this.userScheduledSimulationsService
      .subscribeToUserScheduledSimulations(this.user.uid)
      .subscribe(async (res: any) => {
      const simulations = Object.keys(res)
        .map((key) => ({ key, ...res[key] }))
        .filter((a) => a.status !== 'done' && !a.rescheduled)
        .sort((a, b) => (a.date < b.date ? -1 : 1));

      await this.storage.set('scheduled-simulations-all', simulations);
      this.events.publishData({ 'scheduled-simulations-all': true });

      const simulation = await this.storage.get('scheduled-simulation');
      if (!simulation) {return;}

      const updatedSimulation = res.find((s) => s.key === simulation.key);

      if (!simulations.length || !updatedSimulation) {
        this.storage.remove('scheduled-simulation');
        return;
      }

      // Notify status of the users
      if (!simulation.peerEntered && updatedSimulation.peerEntered === true) {
        this.msg2 = this.translate.instant(this.msg2);
        let msg = `${simulation.peerName}, ${this.msg2}`;
        this.presentToast(msg);
      } else if (updatedSimulation.status_peer == 'is-listening-first-simulation') {
        this.msg1 = this.translate.instant(this.msg1);
        let msg = `${simulation.peerName} ${this.msg1}`;
        this.presentToast(msg);
      } else if (updatedSimulation.status_peer && updatedSimulation.status_peer.includes('is-waiting')) {
        this.msg3 = this.translate.instant(this.msg3);
        let msg = `${simulation.peerName} ${this.msg3}`;
        this.presentToast(msg);
      }

      // Finish simulation if needed
      if (!simulation.firstSimulationDone && updatedSimulation.firstSimulationDone === true) {
        this.events.publishData({ 'finish-simulation': true });
      } else if (!simulation.secondSimulationDone && updatedSimulation.secondSimulationDone === true) {
        this.events.publishData({ 'finish-simulation': true });
      }

      // Update simulation data
      Object.keys(updatedSimulation).forEach((k) => {
        if (simulation[k] != updatedSimulation[k]) simulation[k] = updatedSimulation[k];
      });

      await this.storage.set('scheduled-simulation', simulation);
      this.events.publishData({ 'scheduled-simulation': true });
    });
  }

  validateJourneyDemo() {
    this.authService.getUserJourney(this.user.companyLowercase, this.user.uid).then((res: any) => {
      if (!res) this.openExpiredDemoTimeModal()
      else {
        this.storage.get('user_journey').then(res => {
          if (res && res.journey && this.guideVisit && this.url != '/simulation-peers-add') this.storage.set('guided_visit', true)
        });
      }
    });
  }

  async openExpiredDemoTimeModal() {
    const popover = await this.modalCtrl.create({
      component: GuidedVisitModalComponent,
      cssClass: 'guided-visit-demo-modal-expired',
      backdropDismiss: false,
      keyboardClose: false,
      mode: 'ios',
      componentProps: {
        lastDemoModal: true,
        expiredDemoModal: true
      }
    });

    await popover.present();
  }

  hasUrl(page) {
    let url = this.router.url;
    if (page.url && page.uel == url) return 'selected';
    //////routerLinkActive="{{hasUrl(p)}}"
  }

  checkPages(module: ModuleEnum): void {
    const page = this.appPages.find(x => x.module === module);

    this.appPages.forEach((p: any) => {
      p.showItems = false;
    });

    if (page && page.items) {
      page.showItems = !page.showItems;
    }

  }

  menuOnClick(module: ModuleEnum): void {
    this.checkPages(module)
    const targetRoute = `/all-modules/description/${module}`;
    this.router.navigateByUrl(targetRoute);
  }

  isActiveRoute(route: string): boolean {
    return this.router.url === route;
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 8000,
      position: 'bottom',
      cssClass: 'popup-slide',
      icon: 'checkmark-circle-outline',
      mode: 'ios',
      animated: true,
    });

    await toast.present();
  }

  protected redirectToDashboard(): void {
    const  dashboardPage =  environment.dashboardUrl;
    window.open(dashboardPage, '_blank');
  }

  private getSelectedModule = () => {
    const urlFragments = this.router.url.split('/');
    return this.availableModules.find(x => x === urlFragments[1]) || ModuleEnum.feedback;
  };

  private listenUserJourney(uid: string, companyKey: string): void {
    this.userJourneySubscription = this.userJourneyService.updateJourney(uid, companyKey).subscribe((userJourney) => {
      this.userJourney = userJourney;
      const menuItems = this.appPages.map((menu) => ({
          ...menu,
          status: userJourney?.modules?.[menu.module]?.status,
        }));
      this.appPages = [
        ...menuItems.filter((x) => x.status === 'active'),
        ...menuItems.filter((x) => x.status !== 'active'),
      ];
    });
  }

  private initMenuListener(): void {
    this.events.initEvent('open-menu');
    this.events.getEvent('open-menu').subscribe((opened) => {
      if(opened) {
        this.mainMenu.open().then();
      } else {
        this.mainMenu.close().then();
      }
    });
  }

  private initActiveMenuListener(): void {
    this.events.initEvent('open-menu-module');
    this.events.getEvent('open-menu-module').subscribe(module => {
      const auxModule = this.getSelectedModule();
      this.checkPages(module || auxModule);
    });
  }

  private listenAuthState(): void {
    this.authService.afAuth.authState.subscribe(async (authUser) => {
      if (authUser && authUser.emailVerified) {
        const user: any = await this.userService.getUser(authUser.uid);
        let companyId = user.companyId;
        if (!companyId && user.company) {
          companyId = await this.companyService.getCompanyIdByName(user.company);
        }
        await this.storage.set('user', user);
        await this.initMenuSettings();
        this.appPages = await this.settingsService.getMenuSettings(this.appPages, companyId);

        this.listenUserJourney(authUser.uid, user.companyLowercase);
      }
    });
  }

  private initMenuSettings = async (): Promise<void> => {
    const modules  = await this.moduleService.getCompanyModules();
    const sortModules = modules.sort((a, b) => a.order - b.order);
    this.appPages = sortModules.map(module => ({
        ...module,
        url: `/${module.key}/courses`,
        icon: module.icon || 'construct',
        module: module.key,
        items: [
          {
            id: 1,
            name: 'Introduction',
            url: `/${module.key}/intro`,
            trigger: 'feedback-intro-popover',
            available: false,
            key: 'introduction'
          },
          {
            id: 2,
            name: 'Best Practices',
            url: `/${module.key}/learning`,
            trigger: 'learning-intro-popover',
            key: 'bestPractices'
          },
          {
            id: 3,
            name: 'Avatar simulations',
            url: `/${module.key}/simulations`,
            trigger: 'simulations-popover',
            key: 'avatar'
          },
          {
            id: 4,
            name: 'Peers simulations',
            url: `/${module.key}/simulations-peers`,
            trigger: 'simulations-peers-popover',
            key: 'peer'
          },
        ]
      }));
  };
}
