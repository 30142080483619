import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { Storage } from '@ionic/storage';
import { ModuleEnum } from '../_utils/unums/module.enum';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {

  constructor(
    private firebaseService: FirebaseService,
    private storage: Storage,
  ) {
  }

  public async hasPreTraining(module: ModuleEnum): Promise<boolean> {
    const user = await this.storage.get('user');
    const assessment = await this.firebaseService.getDocument(`user_assessments/${user.uid}/${module}/pre_training`);
    return !!assessment;
  }

  public async getUserAvatarAssessment(userId: string, module: ModuleEnum): Promise<any> {
    return await this.firebaseService.getDocument(`user_assessments/${userId}/${module}/post_avatar`);
  }

  public async updateUserAvatarAssessment(
    userId: string,
    module: ModuleEnum,
    simulationId: string,
    assessmentId: string,
    answers: any
  ): Promise<void> {
    const url = `user_assessments/${userId}/${module}/post_avatar/${simulationId}`;
    return await this.firebaseService.updateDocument(url, assessmentId, answers);
  }

  public async addUserAssessmentResult(url: string, data: any, set: boolean): Promise<string> {
    return await this.firebaseService.addAssessmentResult(url, data, set);
  }

  public async getAssessmentDocument(path: string): Promise<any> {
    return await this.firebaseService.getDocument(path);
  }

  public async getUserAssessmentDocument(userId: string, name: string): Promise<any> {
    return await this.firebaseService.getDocument(`/user_assessments/${userId}/${name}`);
  }

  public async getSimulationLevel(module: string, simulationId: string): Promise<number> {
    const path = `simulations/${module}/${simulationId}/level`;
    return await this.firebaseService.getDocument(path);
  }

  public async hasPreTrainingAssessment(module: ModuleEnum): Promise<boolean> {
    const user = await this.storage.get('user');
    const assessment = await this.firebaseService.getDocument(`user_assessments/${user.uid}/${module}/pre_training`);
    return !!assessment;
  }
}
