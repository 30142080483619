import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private firebaseService: FirebaseService,
    private storage: Storage
  ) {
  }


  public async getForumCompanies(): Promise<any> {
    const query = {orderByChild: 'enabledForum', equalTo: true};
    return await this.firebaseService.getDocumentsListOnce(`companies`, query);
  }

  public async getCompanyIdByName(companyName: string): Promise<string> {
    const companyDoc = await this.firebaseService.getDocumentByIndexData(
      `companies`, 'name_lowercase', companyName.toLowerCase()
    );
    return companyDoc.id;
  }
}
